<template>
  <div id="faq">
    <h1>501 - Not Implemented</h1>
  </div>
</template>

<script>
export default {

}
</script>

<style>
#faq{
    color: black !important;
}
</style>